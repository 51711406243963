<template>
  <div class="asset-container">
    <div class="asset-slider">
      <template v-for="(item, idx) in menus">
        <div  v-if="funCodes(...item.ops)"  :class="['asset-slider-item', item.code == currentName ? 'asset-item-selected' : '']"  :key="idx" @click="menuSelected(item.code)">
          <div v-if="item.code == currentName" class="blockActive"></div>
          <img :src="[ item.code == currentName ? item.iconActive : item.icon]" alt="" :class="['asset-item-img', item.code == currentName ? 'asset-item-imgActive' : '']">
          <div class="asset-item-title">{{item.name}}</div>
        </div>
      </template>
    </div>
    <div class="asset-content">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == currentName" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import OrderSystem from '@/components/work/order/sys/Index'
// import OrderHand from '@/components/work/order/hand/Index'
import OrderDone from '@/components/work/order/done/Index'
import OrderHistory from '@/components/work/order/done/History'
import OrderTotal from '@/components/work/order/search/Index'
import OrganManage from '@/components/work/organ/manage/Index'
import OrganMaintance from '@/components/work/organ/maintance/Index'
import OrganRemote from '@/components/work/organ/remote/Index'
import OrderConfig from '@/components/work/order/config/Index'
// import AlarmDuty from '@/components/config/alarm/duty/Index'
// import FlowMine from '@/components/sys/flow/instance/Mine'
// import Jssdk from './test/Index'
export default {
  name: 'FactorySystemIndex',
  components: {
  },
  data() {
    return {
      isCollapsed: false,
      currentName: 's0', //role,user
      menus: [
        { code: 's0', name: '我的工单', com: OrderSystem, ops: ["wwov", 'wwoe'], icon: this.img.mochaITOM.myJob, iconActive: this.img.mochaITOM.myJobActive },
        { code: 's1', name: '工单管理', com: OrderDone, ops: ['wdov'], icon: this.img.mochaITOM.workManage, iconActive: this.img.mochaITOM.workManageActie },
        { code: 's2', name: '历史工单', com: OrderHistory, ops: ['wdov'], icon: this.img.mochaITOM.workManage, iconActive: this.img.mochaITOM.workManageActie },
        { code: 's3', name: '工单统计', com: OrderTotal, ops: ['wotv'], icon: this.img.mochaITOM.workCensus, iconActive: this.img.mochaITOM.workCensusActie },
        { code: 's4', name: '管理单位', com: OrganManage, ops: ['womv', 'woma', 'wome', 'womd'], icon: this.img.mochaITOM.manageUnit, iconActive: this.img.mochaITOM.manageUnitActive },
        { code: 's5', name: '管养单位', com: OrganMaintance, ops: ["aov", 'aoa', 'aoe', 'aod'], icon: this.img.mochaITOM.raiseUnit, iconActive: this.img.mochaITOM.raiseUnitActive },
        { code: 's6', name: '三遥单位', com: OrganRemote, ops: ["worv", 'wora', 'wore', 'word'], icon: this.img.assetManage.AssetChange, iconActive: this.img.assetManage.AssetChangeActive },
        { code: 's7', name: '工单配置', com: OrderConfig, ops: ['wcov', 'wcoe'], icon: this.img.mochaITOM.workDispose, iconActive: this.img.mochaITOM.workDisposeActive },
      ],
      count: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
  },
  // watch: {
  //   $route: {
  //     handler: function(route) {
  //       this.currentName = route.path.replace(`/index/pole/system/`, '');
  //     },
  //     immediate: true
  //   },
  // },
  mounted: function () {
  },
  methods: {
    clickHide: function () {
      this.count++;
    },
    menuSelected: function (name) {
      if (this.currentName == name) return;
      this.currentName = name;
      // this.$router.push(`/index/pole/system/${name}`);
    },
  }
}
</script>
<style scoped>
.asset-container {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #e3e9ef;
  padding: 3px;
}
.asset-slider {
  width: 250px;
  flex: none;
  margin-right: 5px;
  background-color: #ffffff;
}
.asset-slider-item {
  height: 48px;
  margin-bottom: 5px;
  cursor: pointer;
  line-height: 48px;
  display: flex;
}
.asset-item-selected {
  background-color: #ddeffe;
}
.asset-content {
  width: calc(100% - 350px);
  flex: auto;
  background-color: #ffffff;
  margin: 20px;
  padding: 30px;
}
.blockActive {
  width: 6px;
  height: 48px;
  background: linear-gradient(0deg, #3176fb, #5eb4ff);
  border-radius: 0px 4px 4px 0px;
}
.asset-item-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.asset-item-img {
  width: 20px;
  height: 22px;
  vertical-align: middle;
  margin-top: 13px;
  margin-left: 17px;
  margin-right: 17px;
}
.asset-item-imgActive {
  margin-left: 12px;
}
.asset-item-selected .asset-item-title {
  color: #3880fc;
}
</style>