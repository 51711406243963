<template>
<Modal v-model="showModal" :mask-closable="false" width="600px">
  <div slot="header">{{item.title}}</div>
  <Form ref="form" :model="form" label-position="top">
    <FormItem label="申请延期时间">
      <Input v-model="form.delay" disabled><span slot="append">天</span></Input>
    </FormItem>
    <FormItem label="申请延期原因">
      <Input type="textarea" v-model="form.content" :rows="4" readonly></Input>
    </FormItem>
    <FormItem label="">
      <Checkbox v-model="form.confirmDelay">是否允许延期</Checkbox>
    </FormItem>
    <FormItem v-if="form.confirmDelay" label="确认延期时间">
      <!-- <Input type="number" v-model="form.confirmDelayDays" number><span slot="append">天</span></Input> -->
      <DatePicker v-model="form.confirmDelayDays" format="yyyy-MM-dd HH:mm" type="datetime" style="width: 170px; margin: 0;"></DatePicker>
    </FormItem>
    <FormItem :label="form.confirmDelay ? '确认延期说明' : '禁止延期说明'">
      <Input type="textarea" v-model="form.confirmcontent" :rows="4"></Input>
    </FormItem>
  </Form>
  <div slot="footer">
    <!-- {{step}} - {{row}} -->
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    <Button type="primary" style="margin-right: 8px" @click="ok">确定</Button>
  </div>
</Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalConfirmDelay',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){return {
        orderId: 0, //工单ID，
        cmd: '', //指令类型，多指令共用此窗口时有效
        title: '' //窗口标题
      };}
    },
  },
  data () {
    return {
      showModal: this.value,
      form: {
        orderId: 0,
        cmd: '',
        content: '',
        delay: '',
        confirmDelay: true,
        confirmDelayDays: '',
        confirmcontent: ''
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['funCodes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.form.cmd = this.item.cmd;
        this.form.orderId = this.item.orderId;
        this.form.content = this.item.content;
        this.form.delay = this.item.delay;
        this.form.confirmcontent = '';
        this.form.confirmDelayDays = this.form.delay;
        this.form.confirmDelay = true;
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
  },
  methods: {
    ok: function(){
      this.form.confirmcontent = this.form.confirmcontent.trim();
      this.form.confirmDelayDays = new Date(this.form.confirmDelayDays).format('yyyy-MM-dd HH:mm');
      if(this.form.confirmcontent == ''){
        this.$Message.warning('请输入延期确认意见');
        return;
      }
      this.$emit('saved', this.form);
      this.showModal = false;
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
</style>