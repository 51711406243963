<template>
<Modal v-model="showModal" :mask-closable="false" width="600px">
  <div slot="header">故障判定</div>
  <Form ref="form" :model="form" :label-width="90">
    <FormItem label="故障等级">
      <Select v-model="form.alarmLevel" style="width:200px;" @on-change="alarmLevelChanged">
        <Option v-for="item in levels" :value="item.code" :key="item.code">{{ item.name }}[{{item.code == 4 ? '延期时间自定义' : `延期${item.hours}小时`}}]</Option>
      </Select>
    </FormItem>
    <FormItem label="延期至时间">
      <!-- <Input type="number" v-model="form.delay" style="width: 200px;"><span slot="append">天</span></Input> -->
      <DatePicker v-if="form.alarmLevel == 4" v-model="form.delay" format="yyyy-MM-dd HH:mm" type="datetime" style="width: 170px; margin: 0;"></DatePicker>
      <Input type="text" v-else v-model="form.delay" readonly style="width: 200px;"></Input>
    </FormItem>
    <FormItem label="故障说明">
      <Input type="textarea" v-model="form.content" :rows="4"></Input>
    </FormItem>
  </Form>
  <div slot="footer">
    <!-- {{step}} - {{row}} -->
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    <Button type="primary" style="margin-right: 8px" @click="ok">确定</Button>
  </div>
</Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalAlarmCheck',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){return {
        orderId: 0, //工单ID，
      };}
    },
    levels: {
      type: Array,
      default(){
        return [];
      }
    },
  },
  data () {
    return {
      showModal: this.value,
      form: {
        alarmLevel: 1,
        delay: '',
        content: '',
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['funCodes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // this.getFormData();
        this.form.alarmLevel = 1;
        let now = new Date();
        let its = this.levels.filter(p => p.code == this.form.alarmLevel);
        if(its.length > 0){
          let hours = its[0].hours;
          now.setHours(now.getHours() + hours);
          this.form.delay = now.format('yyyy-MM-dd HH:mm');
        }else{
          now.setDate(now.getDate() + 10);
          this.form.delay = now.format('yyyy-MM-dd HH:mm');
        }
        this.form.content = '';
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
  },
  methods: {
    alarmLevelChanged: function(){
      let now = new Date();
      let its = this.levels.filter(p => p.code == this.form.alarmLevel);
      if(its.length > 0){
        let hours = its[0].hours;
        if(hours > 0){
          now.setHours(now.getHours() + hours);
          this.form.delay = now.format('yyyy-MM-dd HH:mm');
        }else{
          now.setDate(now.getDate() + 10);
          this.form.delay = now.format('yyyy-MM-dd HH:mm');
        }
      }else{
        now.setDate(now.getDate() + 10);
        this.form.delay = now.format('yyyy-MM-dd HH:mm');
      }
    },
    ok: function(){
      this.form.delay = new Date(this.form.delay).format('yyyy-MM-dd HH:mm');
      this.$emit('saved', this.form);
      this.showModal = false;
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
</style>