<template>
<Modal v-model="showModal" :mask-closable="false" width="1200px">
  <div slot="header">{{item.title}}</div>
  <Form ref="form" :model="form" label-position="top">
    <FormItem label="故障列表">
      <vxe-table border show-overflow show-header-overflow :data="alarms" :row-config="{isHover: true}">
        <vxe-column field="done" title="状态" width="70">
          <template #default="params">
            {{params.row.done ? '已消除' : '未消除'}}
          </template>
        </vxe-column>
        <vxe-column field="paramName" title="报警属性" width="150"></vxe-column>
        <vxe-column field="name" title="报警类型" width="150"></vxe-column>
        <vxe-column field="startTime" title="发生时间" width="150"></vxe-column>
        <vxe-column field="extra" title="报警信息"></vxe-column>
      </vxe-table>
    </FormItem>
    <FormItem label="处理情况">
      <Input type="textarea" v-model="form.content" :rows="4"></Input>
    </FormItem>
  </Form>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    <Button type="primary" style="margin-right: 8px" @click="ok">确定</Button>
  </div>
</Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalWorkDone',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){return {
        orderId: 0, //工单ID，
        cmd: '', //指令类型，多指令共用此窗口时有效
        title: '' //窗口标题
      };}
    },
    alarms: {
      type: Array,
      default(){return []}
    },
  },
  data () {
    return {
      showModal: this.value,
      form: {
        orderId: 0,
        cmd: '',
        content: '',
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['funCodes']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels', 'dealAlarmTypes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.form.cmd = this.item.cmd;
        this.form.orderId = this.item.orderId;
        this.form.content = '';
        // this.getFormData();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
  },
  methods: {
    handleEdit: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `故障确定已消除吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/OrderAlarmDone`, {id: params.row.id}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info("故障消除成功");
              params.row.done = true;
            }
          });
        }
      });
    },
    ok: function(){
      let ass = this.alarms.filter(p => !p.done);
      if(ass.length > 0){
        this.$Message.warning('还有未消除的故障');
        return;
      }
      this.form.content = this.form.content.trim();
      if(this.form.content == ''){
        this.$Message.warning('请输入处理情况');
        return;
      }
      this.$emit('saved', this.form);
      this.showModal = false;
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
</style>