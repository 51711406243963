<template>
  <Modal v-model="showModal" :mask-closable="false" width="400px">
    <div slot="header">设置当日值班人员</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="100">
      <FormItem prop="organId" label="当前值班单位">
        {{groupName}}
      </FormItem>
      <FormItem prop="mainId" label="主要值班人员">
        <Select v-model="form.mainId">
          <Option v-for="(item, idx) in users" :key="idx" :value="parseInt(item.id)">{{item.name}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="mainId" label="候补值班人员">
        <Select v-model="form.bakId">
          <Option v-for="(item, idx) in users" :key="idx" :value="parseInt(item.id)">{{item.name}}</Option>
        </Select>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
export default {
  name: 'ModalDutyEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {data:{}};}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      form: {
        organId: 0,
        mainId: 0,
        bakId: 0,
      },
      users: [],
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.groupName = this.item.data.name;
        this.form.organId = this.item.data.id;
        this.getUsers();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
  },
  mounted: function(){
    // this.getProjects();
    // console.log('modal level edit mounted', this.item)
  },
  methods: {
    getUsers: function(){
      this.$axios.post(`//${this.domains.trans}/station/Maintance/QueryRemoteOrganUsers`, {id:this.item.data.id}).then(res => {
        this.$set(this, 'users', res.data);
        this.form.mainId = this.item.data.mainUser;
        this.form.bakId = this.item.data.bakUser;
      })
    },
    ok: async function () {
      if(this.form.organId <= 0){
        this.$Message.warning('请选择一个值班单位');
        return;
      }
      if(this.form.mainId <= 0){
        this.$Message.warning('请选择主要值班人');
        return;
      }
      if(this.form.bakId <= 0){
        this.$Message.warning('请选择候补值班人');
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/Maintance/SetRemoteDuty`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>