<template>
  <Modal v-model="showModal" :mask-closable="false" width="1150px">
    <div slot="header">系统工单 - 当前状态：{{order.statusName}} - {{order.id}}</div>
    <div class="form-body">
      <div class="progress">
        <div class="progress-box">
          <div class="triangle"></div>
          报警生成
        </div>
        <div class="progress-box">
          <div class="triangle"></div>
          <div class="triangle2"></div>待处理
        </div>
        <div class="progress-box">
          <div class="triangle"></div>
          <div class="triangle2"></div>处理中
        </div>
        <div class="progress-box">
          <div class="triangle"></div>
          <div class="triangle2"></div>已解决
        </div>
        <div class="progress-box progress-box-actvie">
          <div class="triangle"></div>
          <div class="triangle2"></div>已关闭
        </div>
      </div>
      <div class="alarm-list">
        <div class="alarm-item-header">
          <span class="small-font" style="margin:0">故障站点：</span>
          {{order.stationName}}
          <span class="small-font">工单生成时间：</span>
          {{order.fireTime}}
          <span class="small-font">故障列表：</span>
          <div style="float: right"><span class="small-font">工单责任人：</span>{{order.dealUserName}}</div>
        </div>
        <vxe-table border show-overflow show-header-overflow :data="alarms" :row-config="{isHover: true}">
          <vxe-column field="done" title="状态" width="70">
            <template #default="params">
              {{params.row.done ? '已消除' : '未消除'}}
            </template>
          </vxe-column>
          <vxe-column field="paramName" title="报警属性" width="150">
            <template #default="params">
              {{params.row.paramName}}
            </template>
          </vxe-column>
          <!-- <vxe-column field="sourceName" title="报警来源" width="100">
            <template #default="params">
              {{params.row.source == 0 ? '系统生成' : '人工上报'}}
            </template>
          </vxe-column> -->
          <vxe-column field="type" title="报警类型" width="150">
            <template #default="params">
              {{params.row.name}}
            </template>
          </vxe-column>
          <vxe-column field="startTime" title="发生时间" width="150"></vxe-column>
          <vxe-column field="doneTime" title="消除时间" width="150">
            <template #default="params">
              {{params.row.done ? params.row.doneTime : '-'}}
            </template>
          </vxe-column>
          <vxe-column field="extra" title="报警信息"></vxe-column>
        </vxe-table>
        <div class="alarm-item-header">
          <span class="small-font" style="margin:0">故障等级：</span>
          {{alarmLevelName}}
          <span class="small-font">故障判定时间：</span>
          {{order.alarmLevel > 0 ? order.levelTime : '-'}}
        </div>
        <div class="alarm-item-header" v-if="order.isDelay">
          <span class="small-font" style="margin:0">申请延期：</span>
          {{order.delayTime}}
          <span class="small-font">延期原因：</span>
          {{order.delayContent}}
          <span class="small-font">延期确认：</span>
          {{order.confirmDelayUserId > 0 ? (order.confirmDelay ? `允许延期至${order.confirmDelayTime}` : '禁止延期') : '未确认'}}
          <span class="small-font">延期确认人：</span>
          {{order.confirmDelayUserId > 0 ? order.confirmDelayUserName : '-'}}
        </div>
      </div>
      <div class="comment-body">
        <div :class="['comment-item', !log.isComment && !log.isOver ? 'wait-done-item' : '']" v-for="(log, idx) in logs" :key="idx">
          <div v-if="log.isComment" :class="['comment-item-content', 'comment-item-border', idx==logs.length-1 ?'blackBold2':'']">
            <span :class="['black', 'blackBold']">留言：{{log.content}}</span>
          </div>
          <div v-else :class="['comment-item-content', 'comment-item-border', idx==logs.length-1 ?'blackBold2':'']">
            <span :class="['black', 'blackBold']">步骤内容：{{log.content}}</span>
            <!-- <span style="margin-right: 10px"></span> -->
          </div>
          <div class="info-details">
            <div class="info-img-list" v-for="(el,index) in log.imageIds" :key="index">
              <img :src="img.setTime" class="info-delImg" style="margin-right: 83px; background-color: #1c1b1bb5;border-radius: 3px;" @click="downImg(el.url)" />
              <img :src="img.light.info.enlargeImg" alt="" class="info-delImg" style="margin-right: 43px;" @click="enlarge(el)" />
              <img v-if="canUploadImage(log)" :src="img.light.info.deleteImg" alt="" class="info-delImg" @click="delImg(log.id, el)" />
              <img :src="`//${domains.trans}/station/config/ViewImage?id=${el}&auth=${token}`" alt="" class="info-img" />
            </div>
            <Upload ref="upload" v-if="canUploadImage(log)" :headers="{auth: token}" :data="{id: log.id}" :show-upload-list="false" :on-success="handleSuccess" :format="['jpg','jpeg','png']"
              :max-size="20480" :on-format-error="handleFormatError" :on-exceeded-size="handleMaxSize" multiple type="drag"
              :action="`//${domains.trans}/station/maintance/SaveWorkImage`" style="border:none">
              <div class="info-img-list">
                <img :src="img.light.info.addImg" alt="" class="info-img" style="cursor: pointer;">
              </div>
            </Upload>
          </div>
          <div v-if="log.isComment" :class="['comment-item-content', 'comment-item-border', idx==logs.length-1 ?'blackBold2':'']" style="justify-content: flex-end;">
            <span style="margin-right: 10px">{{log.userName}} {{log.userId == user.id ? '[我]' : ''}} 于 {{log.endTime}}</span>
          </div>
          <div v-else :class="['comment-item-content', 'comment-item-border', idx==logs.length-1 ?'blackBold2':'']">
            <span>步骤到达时间：{{log.startTime}} {{log.limit ? `步骤处理时限：${log.limitTime}` : '不限时'}} 处理人：{{log.userId > 0 ? log.userName : '无'}}{{log.userId == user.id ? '[我]' : ''}}</span>
            <span v-if="log.isOver" style="margin-right: 10px">步骤完成时间： {{log.endTime}}</span>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <span v-if="order.limit" class="limit-time">时限：{{getLimitTimeName(order.limitTime)}}</span>
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
    <Modal v-model="isShowImg" title="图片放大" width="1000">
      <div style="text-align: center;overflow:auto;"> <img :src="isShowImgUrl" alt="" :style="{width:'100%', verticalAlign: 'middle', transform: `rotate(${rotateAngle}deg)`}"></div>
      <div slot="footer">
        <Button type="info" style="margin-right: 8px" @click="leftRotate">左旋</Button>
        <Button type="info" style="margin-right: 8px" @click="rightRotate">右旋</Button>
        <Button style="margin-right: 8px" type="primary" @click="downImg(isShowImgUrl)">下载</Button>
        <Button style="margin-right: 8px" @click="cancelImgShow">关闭</Button>
      </div>
    </Modal>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalOrderForm',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() {
        return {
          orderId: 0, //工单ID，
        };
      }
    },
  },
  data() {
    return {
      showModal: this.value,
      showMessageModal: false,
      showSelectSourceModal: false,
      showAlarmCheckModal: false,
      showContentModal: false,
      showDelayModal: false,
      showConfirmDelayModal: false,
      showWorkDoneModal: false,
      levels: [
        { code: 1, name: '普通故障', hours: 6 },
        { code: 2, name: '一般故障', hours: 12 },
        { code: 3, name: '重大故障', hours: 72 },
        { code: 4, name: '特殊故障' },
      ],
      actions: [
        { code: 10, name: '接警', type: 'primary', action: this.doReceiveOrder },
        { code: 20, name: '抵达现场', type: 'primary', action: this.doArrivePlace },
        { code: 30, name: '故障判定', type: 'primary', action: this.doAlarmCheck },
        { code: 30, name: '系统误报', type: 'warning', action: this.doMisReport },
        { code: 40, name: '确认误报', type: 'primary', action: this.doConfirmMisReport },
        { code: 40, name: '确认故障', type: 'warning', action: this.doConfirmAlarm },
        { code: 50, name: '确认延期', type: 'primary', action: this.doConfirmDelay },
        { code: 60, name: '故障处理完成', type: 'primary', action: this.doAlarmOrderDone },
        { code: 60, name: '申请延期', type: 'warning', action: this.doRequestDelay },
      ],
      editItem: {},
      loading: false,
      isNew: false,
      message: '',
      order: {},
      logs: [],
      alarms: [],
      logsStatus: 0,
      isShowImg: false,
      isShowImgUrl: null,
      rotateAngle: 0,
    }
  },
  computed: {
    ...mapState('auth', ['user', 'token']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels', 'dealAlarmTypes']),
    alarmLevelName: function () {
      if (!this.order) return '-';
      if (this.order.alarmLevel <= 0) return '-';
      let als = this.levels.filter(p => p.code == this.order.alarmLevel);
      if (als.length == 0) return '-';
      return als[0].name;
    },
    overLogs: function(){
      return this.logs.filter(p => p.isOver);
    },
    waitDoneLog: function(){
      let ls = this.logs.filter(p => !p.isOver);
      if(ls.length > 0)return ls[0];
      return null;
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.getFormData();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
  },
  methods: {
    canUploadImage: function(log){
      // console.log('can up img', log, this.user)
      if(log.userId != this.user.id)return false;
      if(!log.isOver)return true;
      if(new Date().getTime() - new Date(log.endTime).getTime() < 1000 * 60 * 60 * 1)return true;
      // if(log.isComment)return true;
      // if(log.isOver)return false;
      return false;
    },
    cancelImgShow: function(){
      this.isShowImg = false;
    },
    leftRotate: function () {
      this.rotateAngle -= 90;
    },
    rightRotate: function () {
      this.rotateAngle += 90;
    },
    downImg(url) {//下载
      this.$axios.get(url, { responseType: "blob" }).then(response => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(response);
        link.download ='工单图片.png';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    enlarge(el) {//放大
      // console.log(el)
      this.isShowImg = true;
      this.isShowImgUrl = `//${this.domains.trans}/station/config/ViewImage?id=${el}&auth=${this.token}`;
    },
    handleMaxSize() {
      this.$Notice.warning({
        title: "大小限制",
        desc:'不能超过20M'
      });
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "格式限制",
        desc: "必须图片格式"+file.name
      });
    },
    handleSuccess(res) {
      if(res.code != 0){
        this.$Modal.error({
          title: "图片上传失败",
          content: res.data,
        });
        return;
      }else{
        let json = JSON.parse(new Buffer(res.data, 'base64').toString());
        let ls = this.logs.filter(p => p.id == json.id);
        if(ls.length > 0){
          ls[0].imageIds.push(json.fid);
        }
      }
    },
    imgListAjax(id) {
      return `//${this.domains.trans}/station/file/${id}`;
    },
    isBoxActvie(index) {
      let classNmae = '';
      if (index == 0 && this.logsStatus < 20) {
        classNmae = "progress-box-actvie"
      }
      if (index == 1 && 20 <= this.logsStatus && this.logsStatus < 60) {
        classNmae = "progress-box-actvie"
      }
      if (index == 2 && 60 <= this.logsStatus && this.logsStatus < 80) {
        classNmae = "progress-box-actvie"
      }
      if (index == 3 && this.logsStatus == 80) {
        classNmae = "progress-box-actvie"
      }
      if (index == 4 && this.logsStatus == 99) {
        classNmae = "progress-box-actvie"
      }
      return classNmae;
    },
    getLimitTimeName: function (time) {
      let limitTime = new Date(time).getTime();
      let now = new Date().getTime();
      if (limitTime < now) return `${time}[已超时]`;
      let diff = Math.floor((limitTime - now) / 1000 / 60);
      if (diff < 30) return `${time}[余${diff}分]`;
      return time;
    },
    fieldItems: function (field) {
      let result = [];
      if (field.type == 5) {
        let ops = field.format.split(',');
        result.push({ id: 'true', name: ops[0] });
        result.push({ id: 'false', name: ops[1] });
      }
      return result;
    },
    searchDataSource: function (params) {
      this.editItem = { id: params.sourceId, multi: params.multi, col: params.code };
      this.showSelectSourceModal = true;
    },
    getFormData: function () {
      this.$axios.post(`//${this.domains.trans}/station/Maintance/FindWorkHistory`, { orderId: this.item.orderId, time: this.item.fireTime }).then(res => {
        if (res.code !== 0) return;
        this.$set(this, 'order', res.data.order);
        this.$set(this, 'logs', res.data.logs);
        this.logsStatus = res.data.logs.length > 0 ? res.data.logs[res.data.logs.length - 1].status : 0;
        this.$set(this, 'alarms', res.data.alarms);
        for(let item of this.levels){
          if(res.data.levels[item.code]){
            item.hours = res.data.levels[item.code];
          }
        }
      })
    },
    saveAndSend: function (params) {
      if (params.action) {
        params.action();
      }
    },
    doReceiveOrder: function () {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要接警吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/ReceiveOrder`, { id: this.order.id, content: '接警' }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.$Message.info("接警成功");
              this.getFormData();
              this.$emit('saved')
            }
          });
        }
      });
    },
    doArrivePlace: function () {
      this.$Modal.confirm({
        title: "提示",
        content: `确定已抵达现场吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/ArrivePlace`, { id: this.order.id, content: '抵达现场' }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.$Message.info("已抵达现场");
              this.getFormData();
              this.$emit('saved')
            }
          });
        }
      });
    },
    doAlarmCheck: function () {
      this.editItem = this.order;
      this.showAlarmCheckModal = true;
    },
    doMisReport: function () {
      this.editItem = {
        orderId: this.order.id,
        cmd: 'misReport',
        title: '误报反馈',
      };
      this.showContentModal = true;
    },
    alarmCheckResultGot: function (params) {
      // console.log('get alarm check result', params);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要上传故障判定结果吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/AlarmCheck`, Object.assign({ id: this.order.id }, params)).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.$Message.info("故障判定结果上报成功");
              this.getFormData();
              this.$emit('saved')
            }
          });
        }
      });
    },
    doConfirmMisReport: function () { //三遥确认误报
      this.editItem = { title: '三遥确认误报', orderId: this.order.id, cmd: 'confirmMisReport' };
      this.showContentModal = true;
    },
    doConfirmAlarm: function () { //三遥确认故障
      this.editItem = { title: '三遥确认故障', orderId: this.order.id, cmd: 'confirmAlarm' };
      this.showContentModal = true;
    },
    contentResultGot: function (params) {
      console.log('get content result', params);
      switch (params.cmd) {
        case 'misReport': {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/AlarmCheck`, Object.assign({ id: this.order.id }, {
            misReport: true,
            content: params.content,
          })).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.$Message.info("已向系统反馈误报");
              this.getFormData();
              this.$emit('saved')
            }
          });
          break;
        }
        case 'confirmMisReport': {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/RemoteConfirmMisReport`, params).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.$Message.info("故障判定结果上报成功");
              this.getFormData();
              this.$emit('saved')
            }
          });
          break;
        }
        case 'confirmAlarm': {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/RemoteConfirmAlarm`, params).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.$Message.info("故障判定结果上报成功");
              this.getFormData();
              this.$emit('saved')
            }
          });
          break;
        }
      }
    },
    doAlarmOrderDone: function () { //故障处理完成
      this.editItem = { title: '故障处理完成', orderId: this.order.id, cmd: 'orderDone' };
      this.showWorkDoneModal = true;
    },
    workDoneResultGot: function (params) {
      this.$axios.post(`//${this.domains.trans}/station/Maintance/WorkDone`, params).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$Message.info("提交成功");
          this.getFormData();
          this.$emit('saved')
        }
      });
    },
    doRequestDelay: function () { //申请延期
      this.editItem = { title: '申请延期', orderId: this.order.id, cmd: 'requestDelay' };
      this.showDelayModal = true;
    },
    delayResultGot: function (params) {
      // console.log('get delay result', params);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要申请延期至时间 ${new Date(params.delay).format('yyyy-MM-dd HH:mm')} 吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/RequestDelay`, params).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.$Message.info("申请延期成功");
              this.getFormData();
              this.$emit('saved')
            }
          });
        }
      });
    },
    doConfirmDelay: function () { //管理人员确认延期
      this.editItem = { title: '确认延期', orderId: this.order.id, cmd: 'confirmDelay', delay: this.order.delayTime, content: this.order.delayContent };
      this.showConfirmDelayModal = true;
    },
    delayConfirmResultGot: function (params) {
      // console.log('get delay result', params);
      this.$axios.post(`//${this.domains.trans}/station/Maintance/ConfirmDelay`, {
        orderId: this.order.id,
        confirm: params.confirmDelay,
        delay: params.confirmDelayDays,
        content: params.confirmcontent,
      }).then(res => {
        if (res.code !== 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info('延期确认成功');
        this.getFormData();
        this.$emit('saved')
      })
    },
    saveComment: function () { //保存留言并获取最新的留言列表
      if (this.message.length == 0) {
        this.$Message.warning('请输入留言内容');
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/Maintance/SaveComment`, { id: this.order.id, content: this.message }).then(res => {
        if (res.code !== 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info('保存成功');
        this.$set(this, 'logs', res.data);
        this.message = '';
      })
    },
    delImg: function (lid, fid) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content:this.showLang('com.tips.ok.del.picture'),
        onOk: () => {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/DeleteImageById`, {lid, fid}).then(res => {
            if (res.code == 0) {
              this.$Message.info(this.showLang('com.tips.del.success'));
              let ls = this.logs.filter(p => p.id == lid);
              if(ls.length > 0){
                for(let i = 0; i < ls[0].imageIds.length; i++){
                  if(ls[0].imageIds[i] == fid){
                    ls[0].imageIds.splice(i, 1);
                    return;
                  }
                }
              }
            }
          });
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.wait-done-item{
  border: solid 1px blue !important;
  background-color: #3880fc11;
}
.info-delImg {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.info-details {
  /* border: solid 1px red; */
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  /* margin: 15px 0 25px; */
  margin: 0 15px;
}
.info-details >>> .plTableBox .el-table th {
  background-color: #eef6ff !important;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #324252;
}
.info-details >>> .ivu-upload-drag {
  border: none;
}
/* .info-details >>> .ivu-upload{
  background: transparent;
} */
.info-img-list {
  /* border: solid 1px red; */
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  background: #e3e9ef;
  border-radius: 12px;
  margin: 10px;
  position: relative;
}
.info-img {
  max-width: 150px;
  max-height: 150px;
  vertical-align: middle;
}
.small-font{
  font-size: 12px;
  color: gray;
  margin-left: 20px;
}
.alarm-item-header {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  padding: 10px 0;
}
.limit-time {
  float: left;
  color: red;
  font-weight: 600;
}
.form-body {
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  padding: 0;
}
.comment-body {
  /* border: solid 1px red; */
  height: 400px;
  flex: none;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}
.comment-item {
  border: solid 1px rgba(114, 113, 113, 0.37);
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-bottom: 10px;
}
.comment-item-content {
  flex: auto;
  display: flex;
  justify-content: space-between;
}
.comment-item-border {
  /* border-left: 1px solid #c0c5cb; */
  position: relative;
  line-height: 24px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #9ea2a6;
  padding: 15px 0 15px 15px;
}
.blackBold2 {
  color: #68737d;
}
.comment-item-spot {
  width: 13px;
  height: 13px;
  background: #fff;
  border: 3px solid #c0c5cb;
  border-radius: 50%;
  position: absolute;
  left: -7px;
  top: 50%;
  margin-top: -6px;
}
.comment-item-spotActive {
  border: 3px solid #3880fc;
}
.comment-item-user-time {
  border-left: 1px solid #c0c5cb;
  height: 30px;
  flex: none;
  border-top: solid 1px rgba(204, 197, 197, 0.342);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
.comment-footer {
  border-top: solid 1px rgba(204, 197, 197, 0.342);
  /* border: solid 1px blue; */
  /* height: 85px; */
  flex: none;
  display: flex;
  padding: 5px;
}
.wait-done-log{
  /* border: solid 1px red; */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.wait-done-header{
  /* border: solid 1px red; */
  /* height: 150px; */
  flex: none;
  display: flex;
  flex-direction: column;
}
.log-header{
  /* border: solid 1px red; */
  display: flex;
  align-items: center;
  font-weight: bolder;
  font-size: 20px;
  justify-content: space-between;
}
.log-content{
  border-top: solid 1px red;
  border-bottom: solid 1px red;
  height: 50px;
  overflow: auto;
}
.log-footer{
  /* border: solid 1px red; */
  display: flex;
  height: 30px;
  flex: none;
  align-items: center;
}
.input-area {
  width: 300px;
  flex: auto;
}
.btn-area {
  width: 80px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 5px;
}
.progress {
  display: flex;
  /* border: solid 1px red; */
}
.mb10 {
  margin-bottom: 10px;
}
.progress-box {
  width: 174px;
  height: 36px;
  background: #eff5fe;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737d;
  text-align: center;
  line-height: 36px;
  position: relative;
}
.progress-box.progress-box-actvie {
  background: #3880fc;
  color: #ffffff;
}
.black {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #9ea2a6;
  padding-right: 10px;
}
.black.blackBold {
  color: #324252;
}
.progress-box .triangle {
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  background-color: #fff;
  border-left: 10px solid #eff5fe;
  border-top: 16px solid #fff;
  border-bottom: 20px solid #fff;
}
.progress-box .triangle2 {
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  background-color: #eff5fe;
  border-left: 10px solid #fff;
  border-top: 16px solid #eff5fe;
  border-bottom: 20px solid #eff5fe;
}
.progress-box-actvie.progress-box .triangle {
  background-color: #fff;
  border-left: 10px solid #3880fc;
}
.progress-box-actvie.progress-box .triangle2 {
  background-color: #3880fc;
  border-top: 16px solid #3880fc;
  border-bottom: 20px solid #3880fc;
}
.showImgBox {
  width: 300px;
  height: 300px;
  border: 1px solid #b7afaf;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 5px;
}
.showImgBox img {
  max-width: 297px;
  max-height: 297px;
}
</style>