<template>
  <div class="flow-container">
    <div class="flow-menu">
      工单名称：
      <Input class="input-area" type="text" v-model="filter.name" clearable style="width: 150px; margin-right: 10px;"></Input>
      查询时段：
      <DatePicker v-model="filter.start" format="yyyy-MM-dd HH:mm:ss" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
      至
      <DatePicker v-model="filter.end" format="yyyy-MM-dd HH:mm:ss" type="datetime" style="width: 170px; margin: 0 10px;"></DatePicker>
      <Button v-if="funCodes('wdov')" type="primary" @click="getList">查询</Button>
    </div>
    <div class="flow-content" ref="table">
      <vxe-table border show-overflow show-header-overflow :data="history" :height="tabHeight" :row-config="{isHover: true}">
        <!-- <vxe-column field="id" title="ID" width="60"></vxe-column> -->
        <vxe-column field="statusName" title="工单状态" width="100"></vxe-column>
        <vxe-column field="limitTime" title="限时" width="210">
          <template #default="params">
            {{getLimitTimeName(params)}}
          </template>
        </vxe-column>
        <vxe-column field="stationName" title="所在站点" width="210"></vxe-column>
        <vxe-column field="name" title="工单名称"></vxe-column>
        <vxe-column field="dealUserName" title="负责人" width="110"></vxe-column>
        <vxe-column field="fireTime" title="工单发起时间" width="150"></vxe-column>
        <vxe-column field="startTime" title="当前步骤时间" width="150"></vxe-column>
        <vxe-column width="175" title="操作" fixed="right">
          <template #default="params">
            <Button v-if="funCodes('wdov')" size="small" type="info" @click="handleEdit(params)" style="margin-right: 5px">查看工单</Button>
            <Button v-if="funCodes('wdod')" size="small" type="error" @click="handleDelete(params)" style="margin-right: 0">删除工单</Button>
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager perfect :current-page="filter.index" :page-size="filter.size" :total="count" :page-sizes="[15, 50, 100, 200, 500]" :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']" @page-change="handlePageChange">
      </vxe-pager>
    </div>
    <ModalOrderForm v-model="showTestModal" :item="editItem" @saved="getList" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalOrderForm from '@/components/work/order/sys/ModalOrderForm'
export default {
  name: 'WorkFlowDefineIndex',
  components: {
    ModalOrderForm,
  },
  props: {
    sys: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      showDefineModel: false,
      showStepModel: false,
      showTestModal: false,
      tabHeight: 500,
      history: [],
      count: 0,
      editItem: {},
      filter: {
        start: '',
        end: '',
        name: '',
        size: 15,
        index: 1,
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 200);
    let now = new Date();
    now.setDate(now.getDate() + 1);
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 8);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
    // this.getForms();
    this.getList();
  },
  methods: {
    getLimitTimeName: function (params) {
      if (params.row.status == 99) return '已结束';
      if (!params.row.limit) return '不限时';
      let limitTime = new Date(params.row.limitTime).getTime();
      let now = new Date().getTime();
      if (limitTime < now) return `${params.row.limitTime}[已超时]`;
      let diff = Math.floor((limitTime - now) / 1000 / 60);
      if (diff < 30) return `${params.row.limitTime}[余${diff}分]`;
      return params.row.limitTime;
    },
    handlePageChange: function ({ currentPage, pageSize }) {
      this.filter.index = currentPage;
      this.filter.size = pageSize;
      this.getList();
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 55;
    },
    handleEdit: function (params) {
      this.editItem = { orderId: params.row.orderId };
      this.showTestModal = true;
    },
    getList: function () {
      this.filter.start = new Date(this.filter.start).format('yyyy-MM-dd HH:mm:ss');
      this.filter.end = new Date(this.filter.end).format('yyyy-MM-dd HH:mm:ss');
      this.$axios.post(`//${this.domains.trans}/station/Maintance/QueryUndoneWorkOrder`, this.filter).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.count = res.data.count;
          this.$set(this, 'history', res.data.list);
        }
      });
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除工单 ${params.row.name} 吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/DeleteWorkOrder`, {orderId: params.row.orderId}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info(`删除成功`);
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.flow-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flow-menu {
  height: 40px;
  margin-bottom: 5px;
  flex: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 3px;
}
.flow-content {
  height: 500px;
  flex: auto;
  padding: 3px;
  border-radius: 6px;
  overflow: hidden;
}
</style>
