<template>
<div class="flow-container">
  <div class="flow-menu">
    <!-- 流程名称：
    <Input class="input-area" type="text" v-model="filter.content" clearable style="width: 150px; margin-right: 10px;"></Input> -->
    统计时段：
    <DatePicker v-model="filter.start" format="yyyy-MM-dd" type="date" style="width: 170px; margin-right: 10px;"></DatePicker>
    至
    <DatePicker v-model="filter.end" format="yyyy-MM-dd" type="date" style="width: 170px; margin: 0 10px;"></DatePicker>
    <Button v-if="funCodes('wdov')" type="primary" @click="getList">查询</Button>
  </div>
  <div class="flow-content" ref="table">
    <vxe-table border show-overflow show-header-overflow :data="history" :height="tabHeight" :row-config="{isHover: true}">
      <vxe-column field="name" title="工单状态" width="200"></vxe-column>
      <vxe-column field="count" title="工单数量" width="300"></vxe-column>
    </vxe-table>
  </div>
  <ModalOrderForm v-model="showTestModal" :item="editItem" @saved="getList"/>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalOrderForm from '@/components/work/order/sys/ModalOrderForm'
export default {
  name: 'WorkFlowDefineIndex',
  components: {
    ModalOrderForm,
  },
  props: {
    sys: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      showDefineModel: false,
      showStepModel: false,
      showTestModal: false,
      tabHeight:500,
      history: [],
      count: 0,
      editItem: {},
      filter: {
        start: '',
        end: '',
        // content: '',
        // size: 15,
        // index: 1,
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 200);
    let now = new Date();
    now.setDate(now.getDate() + 1);
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 8);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
    // this.getForms();
    this.getList();
  },
  methods: {
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 0;
    },
    handleEdit: function(params){
      this.editItem = {orderId: params.row.id};
      this.showTestModal = true;
    },
    getList: function(){
      this.filter.start = new Date(this.filter.start).format('yyyy-MM-dd HH:mm:ss');
      this.filter.end = new Date(this.filter.end).format('yyyy-MM-dd HH:mm:ss');
      this.$axios.post(`//${this.domains.trans}/station/Maintance/QueryOrderStatus`, this.filter).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'history', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.flow-container{
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flow-menu{
  height: 40px;
  margin-bottom: 5px;
  flex: none;
  border-radius: 6px;
  display: flex;
  padding: 3px;
  align-items: center;
}
.flow-content{
  height: 500px;
  flex: auto;
  padding: 3px;
  border-radius: 6px;
  overflow: hidden;
}
</style>
