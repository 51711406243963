<template>
<Modal v-model="showModal" :mask-closable="false" width="600px">
  <div slot="header">{{item.title}}</div>
  <Form ref="form" :model="form" label-position="top">
    <FormItem label="意见内容">
      <Input type="textarea" v-model="form.content" :rows="4"></Input>
    </FormItem>
  </Form>
  <div slot="footer">
    <!-- {{step}} - {{row}} -->
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    <Button type="primary" style="margin-right: 8px" @click="ok">确定</Button>
  </div>
</Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalCommonContent',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){return {
        orderId: 0, //工单ID，
        cmd: '', //指令类型，多指令共用此窗口时有效
        title: '' //窗口标题
      };}
    },
  },
  data () {
    return {
      showModal: this.value,
      form: {
        orderId: 0,
        cmd: '',
        content: '',
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['funCodes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.form.cmd = this.item.cmd;
        this.form.orderId = this.item.orderId;
        this.form.content = '';
        // this.getFormData();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
  },
  methods: {
    ok: function(){
      this.form.content = this.form.content.trim();
      if(this.form.content == ''){
        this.$Message.warning('请输入意见内容');
        return;
      }
      this.$emit('saved', this.form);
      this.showModal = false;
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
</style>