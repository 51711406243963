<template>
<div class="flow-container">
  <!-- <div class="flow-menu">
    
  </div> -->
  <div class="flow-content" ref="table">
    <vxe-table border show-overflow show-header-overflow :data="flows" :height="tabHeight" :row-config="{isHover: true}">
      <vxe-column field="statusName" title="工单状态" width="100"></vxe-column>
      <vxe-column field="limitTime" title="限时" width="210">
        <template #default="params">
          {{getLimitTimeName(params)}}
        </template>
      </vxe-column>
      <vxe-column field="stationName" title="所在站点" width="210"></vxe-column>
      <vxe-column field="name" title="工单名称"></vxe-column>
      <vxe-column field="dealUserName" title="负责人" width="110"></vxe-column>
      <vxe-column field="fireTime" title="工单发起时间" width="150"></vxe-column>
      <vxe-column field="startTime" title="当前步骤时间" width="150"></vxe-column>
      <!-- <vxe-column field="alarmContent" title="故障描述"></vxe-column> -->
      <vxe-column width="120" title="操作" fixed="right">
        <template #header>
          <Button v-if="funCodes('wwov')" size="small" type="primary" @click="getList">刷新</Button>
        </template>
        <template #default="params">
          <Button v-if="funCodes('wwoe')" size="small" type="info" @click="handleEdit(params)" style="margin-right: 5px">处理工单</Button>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange">
    </vxe-pager>
  </div>
  <ModalOrderForm v-model="showTestModal" :item="editItem" @saved="getList"/>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalOrderForm from './ModalOrderForm'
export default {
  name: 'WorkFlowDefineIndex',
  components: {
    ModalOrderForm,
  },
  props: {
    sys: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      showDefineModel: false,
      showStepModel: false,
      showTestModal: false,
      tabHeight:500,
      filter: {
        index: 1,
        size: 15
      },
      flows: [],
      flowId: 0,
      editItem: {},
      forms: [],
      total: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 200);
    // this.getForms();
    this.getList();
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    getLimitTimeName: function(params){
      if(params.row.status == 99)return '已结束';
      if(!params.row.limit)return '不限时';
      let limitTime = new Date(params.row.limitTime).getTime();
      let now = new Date().getTime();
      if(limitTime < now)return `${params.row.limitTime}[已超时]`;
      let diff = Math.floor((limitTime - now) / 1000 / 60);
      if(diff < 30)return `${params.row.limitTime}[余${diff}分]`;
      return params.row.limitTime;
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 46;
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showDefineModel = true;
    },
    handleEdit: function(params){
      this.editItem = {orderId: params.row.orderId};
      this.showTestModal = true;
    },
    flowSaved: function(){
      this.getList();
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/Maintance/QuerySysOrder`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'flows', res.data.list);
          this.total = res.data.count;
        }
      });
    },
  }
}
</script>
<style scoped>
.flow-container{
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flow-menu{
  height: 40px;
  margin-bottom: 5px;
  flex: none;
  border-radius: 6px;
  display: flex;
  padding: 3px;
}
.flow-content{
  height: 500px;
  flex: auto;
  padding: 3px;
  border-radius: 6px;
  overflow: hidden;
}
</style>
